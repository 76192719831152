import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },


  {
    exact: true,
    path: "/view-user-details",
     guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/viewUserDetails")),
  },

  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },

  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/changePassword",
    
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },
  { 
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/user-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement")),
  },

  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faqManagement/AddFaq")),
  },
  {
    exact: true,
    path: "/view-faq",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faqManagement/ViewFaq")),
  },
  {
    exact: true,
    path: "/edit-faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faqManagement/EditFaq")),
  },
  {
    exact: true,
    path: "/faq-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faqManagement/index")),
  },
  {
    exact: true,
    path: "/static-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/StaticManagement/index")),
  },

  {
    exact: true,
    path: "/view-static",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/StaticManagement/ViewStatic.js")),
  },
  
  {
    exact: true,
    path: "/edit-static",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/StaticManagement/EditStatic.js")),
  },
  // {
  //   exact: true,
  //   path: "/static-content-management",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/staticContent")),
  // },


  // {
  //   exact: true,
  //   path: "/banner-management",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/bannerManagement")),
  // },
  // {
  //   exact: true,
  //   path: "/logo-settings",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/siteSettings/logoSettings")),
  // },


  // {
  //   exact: true,
  //   path: "/view-banner",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/bannerManagement/viewBanner")),
  // },
  // {
  //   exact: true,
  //   path: "/add-banner",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/bannerManagement/addNewBanner")),
  // },


 
  {
    exact: true,
    path: "/notifications",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notifications/index")),
  },
 
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
